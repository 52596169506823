import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MyConsultations from "./MyConsultations";
import NewConsultation from "./NewConsultation";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Consultations() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid
      sx={{ height: "100%" }}
      container
      direction="column"
      flexWrap="nowrap"
    >
      <Grid item sx={{ zIndex: 1 }}>
        <Box sx={{borderBottom:1, borderColor:'divider'}}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="My Consultations" {...a11yProps(0)} />
            <Tab label="Create New" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Grid>
      <Grid item sx={{ flexGrow: 1, overflow: "scroll" }}>
        <CustomTabPanel value={value} index={0}>
          <MyConsultations />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <NewConsultation />
        </CustomTabPanel>
      </Grid>
    </Grid>
  );
}
