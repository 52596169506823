import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Paper, Box, Button, Typography, LinearProgress } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getVitalsReportApi } from "../../api/vitals";
import useFetch from "../../hooks/useFetch";
import VitalsChart from "./Report";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function WebcamCapture() {
  const { data, error, loading, request } = useFetch(getVitalsReportApi);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const url = URL.createObjectURL(file);
    setPreviewUrl(url);
  };
  const videoRef = useRef(null);

  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("video", selectedFile);

    try {
      await request(formData);
      // Handle the response from the server (e.g., display results)
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <Grid
        sx={{ height: "100%", padding: 2 }}
        container
        columnSpacing={2}
        flexWrap="nowrap"
      >
        <Grid item sx={{}} xs={3}>
          <Paper
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 2,
            }}
          >
            <div>
              <input type="file" accept="video/*" onChange={handleFileChange} />
              <Button
                variant="outlined"
                onClick={handleUpload}
                disabled={!selectedFile}
              >
                Upload and Analyze
              </Button>
            </div>

            {previewUrl && (
              <video ref={videoRef} width="320" height="240" controls>
                <source src={previewUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Paper>
          {/* <Paper
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 2,
            }}
          >
            <video ref={videoRef} type="video/mp4" autoPlay />
            <br></br>
            {!isRecording && (
              <Button variant="contained" onClick={handleStartRecording}>
                Start Recording
              </Button>
            )}
            {isRecording && (
              <Button variant="contained" onClick={handleStopRecording}>
                Stop Recording
              </Button>
            )}
          </Paper> */}
        </Grid>
        <Grid id item xs={9} sx={{}}>
          {loading && <LinearProgress />}
          {!loading && data && (
            <Paper variant="outlined" sx={{ height: "100%" }}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                     
                    <Tab label="Vitals" {...a11yProps(0)} />
                    <Tab label="Depression Video" {...a11yProps(1)} />
                    <Tab label="Depression Audip " {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  {data.basicVitals?.heart_rate && (
                    <VitalsChart data={data.basicVitals} />
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {data?.videoVitals && (
                    <iframe
                      src={`data:application/pdf;base64,${data.videoVitals.pdf_base64}`}
                      type="application/pdf"
                      title="Video"
                      width="100%"
                      height="1000px" // Adjust height as needed
                    />
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {data?.audioVitals?.audio_depression && (
                    <iframe
                      src={`data:application/pdf;base64,${data.audioVitals.audio_depression}`}
                      type="application/pdf"
                      title="audio"
                      width="100%"
                      height="1000px" // Adjust height as needed
                    />
                  )}
                </TabPanel>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default WebcamCapture;
