import useFetch from "./useFetch";
import {
  getMyConsultationsApi,
  getConsultationApi,
  createConsultationApi,
} from "../api/consultation";
import { getChatAuthApi } from "../api/chat";
export const useMyConsultations = () => {
  const { data, error, loading, request } = useFetch(getMyConsultationsApi);
  return { getMyConsultations: request, data, error, loading };
};

export const useGetConsultation = () => {
  const { data, error, loading, request } = useFetch(getConsultationApi);
  return { getConsultation: request, data, error, loading };
};

export const useCreateConsultation = () => {
  const { data, error, loading, request } = useFetch(createConsultationApi);
  return { createConsultation: request, data, error, loading };
};
export const useGetChatAuth = () => {
  const { data, error, loading, request } = useFetch(getChatAuthApi);
  return { getChatAuth: request, data, error, loading };
};
