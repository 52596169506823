import React, { useEffect, useState } from "react";
import {
  CallControls,
  CallingState,
  SpeakerLayout,
  StreamCall,
  StreamTheme,
  StreamVideo,
  StreamVideoClient,
  useCallStateHooks,
} from '@stream-io/video-react-sdk';
import './chat.css';

import Loader from "../Utility/Loader";
import { useNavigate } from "react-router-dom";
import { useGetChatAuth } from "../../hooks/useConsultation";

export const MyUILayout = () => {
  const { useCallCallingState } = useCallStateHooks();
  const callingState = useCallCallingState();

  if (callingState !== CallingState.JOINED) {
    return <div>Loading...</div>;
  }

  return (
    <StreamTheme>
      <SpeakerLayout participantsBarPosition="bottom" />
      <CallControls />
    </StreamTheme>
  );
};
const ChatView = ({ consultation }) => {
  const { getChatAuth, data: chatAuth, loading } = useGetChatAuth();
  const [call, setCall] = useState();
  const [client, setCallClient] = useState();

  const caseId = consultation.id;


  useEffect(() => {
    getChatAuth();
  }, []);

  useEffect(() => {
    const initChat = async () => {
      if (caseId && chatAuth) {
        const apiKey = chatAuth.apiKey
        const token = chatAuth.authToken
        const userId = chatAuth.userId
        const callId = caseId; // the call id can be found in the "Credentials" section
      // set up the user object
        const user = {
          id: userId,
          name: 'Member',
        };  
        const client = new StreamVideoClient({ apiKey, user, token });
        const call = client.call('default', callId);
        call.join({ create: true });
        setCallClient(client);
        setCall(call);
      }
      // return () => unSubscribeEvents(channelInt);
    };

    initChat();
  }, [caseId, chatAuth]);

  if (!client && !call) {
    return <Loader />;
  } else {
    return (
      <StreamVideo client={client}>
      <StreamCall call={call}>
        <MyUILayout />
      </StreamCall>
    </StreamVideo>
    );
  }
};

export default ChatView;
