import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ArrowRight from "@mui/icons-material/KeyboardArrowRightOutlined";
import CreateConsultation from "../ConsultationForm";

const CardItem = ({ item, handleClickOpen }) => {
  return (
    <Card
      sx={{
        margin: 2,
        marginBottom: 4,
        minHeight: "120px",
        cursor: "pointer",
        borderLeft: `solid 4px ${item.color}`,
      }}
      onClick={() => handleClickOpen(item)}
    >
      <CardContent>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ mb: 1 }}
        >
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Typography sx={{ ml: 0 }} color="text.secondary" fontWeight={500}>
              {item.title}
            </Typography>
          </Box>
          <ArrowRight />
        </Box>
        <Typography variant="body2" color="text.secondary">
          {item.description}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default function NewConsultation() {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("");

  const handleClickOpen = (item) => {
    setOpen(true);
    setType(item);
  };

  const items = [
    {
      title: "Create a journal ",
      type: 1,
      color: "#1ab6f5",
      description: "Create a consultation to log your journal",
      formDescription: "Please label the new journal consultation with brief (3 words or less) description on what you'd like to log.",
    },
    {
      title: "Chat with a doctor",
      type: 2,
      color: "#07bd7a",
      formDescription: "Please label the new consultation with brief (3 words or less) description of what you'd like to discuss.",
      description:
        "Chat with one of our doctors now about a medical or welness question.",
    },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <CreateConsultation open={open} item={type} setOpen={setOpen} />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {items.map((item, index) => (
          <CardItem
            item={item}
            index={index}
            handleClickOpen={handleClickOpen}
          />
        ))}
      </List>
    </Box>
  );
}
