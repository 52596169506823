import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Paper, Box, Button, Typography, LinearProgress } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getVitalsListApi, getVitalsReportApi } from "../../api/vitals";
import useFetch from "../../hooks/useFetch";
import VitalsChart from "./Report";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function WebcamRecorder() {
  const { data, error, loading, request } = useFetch(getVitalsReportApi);
  const [value, setValue] = React.useState(0);
  const [recording, setRecording] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedBlobs = useRef([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      videoRef.current.srcObject = stream;
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          recordedBlobs.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(recordedBlobs.current, { type: "video/mp4" });
        setVideoBlob(blob);
      };

      mediaRecorder.start();
      setRecording(true);
    } catch (error) {
      console.error("Error accessing webcam:", error);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const uploadVideo = async () => {
    if (videoBlob) {
      const formData = new FormData();
      formData.append("video", videoBlob, "webcam-video.webm");

      try {
        await request(formData);
        // const response = await fetch("/upload", {
        //   method: "POST",
        //   body: formData,
        // });
        // const data = await response.json();
        console.log("Upload successful:", data);
      } catch (error) {
        console.error("Error uploading video:", error);
      }
    }
  };

  return (
    <div>
      <Grid
        sx={{ height: "100%", padding: 2 }}
        container
        columnSpacing={2}
        flexWrap="nowrap"
      >
        <Grid item sx={{}} xs={3}>
          <div className={{ width: "100px", height: "100px" }}>
            <video ref={videoRef} autoPlay muted />
          </div>
          <div>
            {!recording && (
              <Button variant="outlined" onClick={startRecording}>
                Start Recording
              </Button>
            )}
            {recording && (
              <Button variant="outlined" onClick={stopRecording}>
                Stop Recording
              </Button>
            )}
            {videoBlob && (
              <Button
                variant="outlined"
                onClick={uploadVideo}
                sx={{ marginLeft: 2 }}
              >
                Upload
              </Button>
            )}
             
          </div>
        </Grid>
        <Grid id item xs={9} sx={{}}>
          {loading && <LinearProgress />}
          {!loading && data && (
            <Paper variant="outlined" sx={{ height: "100%" }}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                     
                    <Tab label="Vitals" {...a11yProps(0)} />
                    <Tab label="Depression Video" {...a11yProps(1)} />
                    <Tab label="Depression Audip " {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  {data.basicVitals?.heart_rate && (
                    <VitalsChart data={data.basicVitals} />
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {data?.videoVitals && (
                    <iframe
                      src={`data:application/pdf;base64,${data.videoVitals.pdf_base64}`}
                      type="application/pdf"
                      title="Video"
                      width="100%"
                      height="1000px" // Adjust height as needed
                    />
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {data?.audioVitals?.audio_depression && (
                    <iframe
                      src={`data:application/pdf;base64,${data.audioVitals.audio_depression}`}
                      type="application/pdf"
                      title="audio"
                      width="100%"
                      height="1000px" // Adjust height as needed
                    />
                  )}
                </TabPanel>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default WebcamRecorder;
