import React from "react";
import { Line } from "react-chartjs-2";
import { Grid, Paper, Typography } from "@mui/material";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const vitalValues = {
  heart_rate: {
    confidence: 0.8795396090381675,
    note: "Estimate of the global heart rate using VitalLens, along with a confidence level between 0 and 1.",
    unit: "bpm",
    value: 60.403355741985656,
  },
  ppg_waveform: {
    confidence: [
      0.00019356608390808105, 1.4603137969970703e-5, 9.804964065551758e-6,
      0.9999865293502808, 0.9661086797714233, 0.9957488775253296,
      0.9989941716194153, 0.999385416507721, 0.7175254225730896,
      0.999780535697937, 1.0, 0.9999991655349731, 0.9999986290931702,
      0.9999516010284424, 0.9998844861984253, 0.999997615814209,
      0.9999992847442627, 0.9999861717224121, 0.9999984502792358,
      0.999999463558197, 0.9999996423721313, 0.9999998807907104, 1.0, 1.0, 1.0,
      0.9999999403953552, 0.9999998807907104, 0.9999971389770508,
      0.9999902248382568, 0.9999997615814209, 1.0, 0.9999995231628418, 1.0, 1.0,
      0.9999622702598572, 0.9999936819076538, 0.9999812245368958,
      0.9361482262611389, 0.9528028964996338, 0.9946777820587158,
      0.6471691131591797, 0.9922971725463867, 0.9999724626541138,
      0.9991518259048462, 0.9999507665634155, 0.9998528361320496,
      0.9991968870162964, 0.9999273419380188, 0.9999998807907104,
      0.9999830722808838, 0.9999984502792358, 0.999998927116394,
      0.9999995231628418, 1.0, 0.9999997615814209, 0.9999908804893494, 1.0,
      0.9999999403953552, 0.9999964237213135, 0.9999884366989136,
      0.9999986886978149, 0.9994571208953857, 0.9960792064666748,
      0.9877995252609253, 0.9779325723648071, 0.9995675683021545,
      0.12218782305717468, 0.004210084676742554, 0.48456841707229614,
      0.9995250701904297, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
      1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 0.9999991655349731, 0.9999998807907104,
      0.9999992847442627, 0.9999717473983765, 0.9999977350234985,
      0.9999765157699585, 0.9999653697013855, 0.9999917149543762,
      0.9999980330467224, 0.9999885559082031, 0.9999973773956299,
      0.9999951124191284, 0.9995065927505493, 0.9989829659461975,
      0.6708621978759766, 0.001386791467666626, 0.0018520355224609375,
      0.288211464881897, 0.20223087072372437, 0.9835541248321533,
      0.13006916642189026, 0.03244462609291077, 0.031041234731674194,
      0.2893281877040863, 0.4102479815483093, 0.0076132118701934814,
      0.8972668051719666, 0.9898734092712402, 0.9999813437461853,
      0.999998927116394, 0.9999992847442627, 0.9999998807907104,
      0.9999996423721313, 0.9999998807907104, 0.9999997615814209,
      0.9999997615814209, 0.9999998807907104, 1.0, 0.9999997615814209,
      0.9995411038398743, 0.9979785680770874, 0.9998246431350708,
      0.10904157161712646, 0.0012088418006896973, 7.87973403930664e-5,
      6.452202796936035e-5, 0.001803278923034668, 0.45121878385543823,
      0.9532787799835205, 0.9993860721588135, 0.9999982118606567, 1.0, 1.0, 1.0,
      1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 0.9999971389770508,
      0.9999973773956299, 0.9999837279319763, 0.9999857544898987,
      0.9999997019767761, 0.9999837875366211, 0.9999850988388062,
      0.9999995231628418, 1.0, 0.9999990463256836, 0.9999758005142212,
      0.999991774559021, 0.8594338893890381, 0.08946478366851807,
      0.0031661391258239746, 9.03010368347168e-5, 1.6570091247558594e-5,
      1.9997358322143555e-5, 0.00019440054893493652, 0.008017867803573608,
      0.18822121620178223, 0.8941632509231567, 0.9997410774230957,
      0.9999972581863403, 0.9999977350234985, 0.9999908804893494, 1.0, 1.0,
      0.9999998807907104, 0.9999991655349731, 0.9999980926513672,
      0.9999961853027344, 0.9999997615814209, 0.9999971389770508,
      0.9999997019767761, 0.9999992847442627, 0.9999976754188538,
      0.9999598264694214, 0.9999051690101624, 0.9976605772972107,
      0.0729033350944519, 0.00855281949043274, 0.002040177583694458,
      0.1213967502117157, 0.32792288064956665, 0.5216181874275208,
      0.9999991655349731, 1.0, 1.0, 1.0, 0.9999999403953552, 1.0,
      0.9999999403953552, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
      0.9999998211860657, 0.9999877214431763, 0.999999463558197,
      0.999987006187439, 0.992908239364624, 0.9983764886856079,
      0.9971492290496826, 0.03051447868347168, 0.4915846586227417,
      0.9998703002929688, 0.9999803900718689, 0.9997811317443848,
      0.9996181130409241, 0.9999960064888, 0.9999991655349731,
      0.9999998211860657, 1.0, 1.0, 1.0, 1.0, 1.0, 0.9999999403953552, 1.0, 1.0,
      1.0, 0.9999998807907104, 0.9999998807907104, 0.9997742176055908,
      0.9917618632316589, 0.9999940991401672, 0.9982914328575134,
      0.11218851804733276, 0.0223330557346344, 0.9999068975448608, 1.0,
      0.9999998211860657, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
      1.0, 1.0, 1.0, 1.0, 1.0, 0.9999533891677856, 1.0, 0.9999891519546509,
      0.8864643573760986, 0.11537429690361023, 0.6693349480628967,
      0.9679841995239258, 0.9980924129486084, 0.5489596724510193,
      0.995856761932373, 0.9999993443489075, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
      1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
      0.9999997615814209, 0.9999999403953552, 0.9999919533729553,
      0.9999606609344482, 0.9999945759773254, 0.9999806880950928,
      0.977802574634552, 0.9988465309143066, 0.9990232586860657,
      0.5461615920066833, 0.57722407579422, 0.373843252658844,
      0.16793164610862732, 0.9985336065292358, 0.9996132850646973, 1.0, 1.0,
      1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
      1.0, 0.9999999403953552, 1.0, 1.0, 0.9999996423721313, 1.0,
      0.9999998211860657, 0.9999998211860657, 0.9999938011169434,
      0.9997790455818176, 0.997397780418396, 0.9992066025733948,
      0.8312860727310181, 0.38133686780929565, 0.03971162438392639,
      0.6247305870056152, 0.993910551071167, 0.9999656677246094,
      0.9999963641166687, 1.0, 1.0, 1.0, 0.9999999403953552, 1.0, 1.0, 1.0, 1.0,
      1.0, 1.0, 1.0, 1.0, 1.0,
    ],
    data: [
      0.2722666765391592, 0.6753270036537943, 0.4605835833249685,
      0.08042946958398442, -0.4111419146038331, -0.19532251751559151,
      0.01346098051923665, 0.11026574824338511, 0.36197113625785987,
      0.5868736591651343, 0.9374119281649473, 0.9476596789610953,
      0.7182049913021394, 0.37982120017251186, 0.2262019973648984,
      0.26003210914181274, 0.378838912557278, 0.47361928128314745,
      0.5052405317725626, 0.39915414083128287, 0.10764536247519031,
      -0.2171166085541666, -0.35669201722229144, -0.5669299059567979,
      -0.8009370191889497, -1.0485447957043252, -0.9696172332321591,
      -0.8446528409589669, -0.8202109627178166, -0.8977109039230926,
      -0.8790283858730954, -0.7948320401345721, -0.7406678902590046,
      -0.5686183310478642, -0.5266612692328035, -0.3673839344879011,
      -0.14628207809145916, 0.05110638400769728, 0.12345102501277629,
      0.24150656347688318, 0.2990095731650506, 0.2734111376732289,
      0.008395296900651938, 0.0014200034816382553, 0.07348459122259102,
      0.09700572088476518, 0.056529575043774996, -0.04094233068262542,
      -0.13118096803638415, -0.1972500169971522, -0.20045321099508465,
      -0.2515371991629213, -0.4042547153785545, -0.551933732371056,
      -0.6328476302888327, -0.6633538861969823, -0.7164174843787746,
      -0.7707070978341766, -0.7770852980696679, -0.7476942619065052,
      -0.7041707638063067, -0.6095399348055226, -0.5477592451414662,
      -0.4941510705126483, -0.5904276365223737, -0.2770179854452872,
      0.009092375370705468, 0.7144130042562354, 1.1114502802336481,
      1.761234895892424, 2.136038663628126, 2.373855455722793,
      2.344133422906846, 2.179471202908958, 1.9935654200615278,
      1.697237047950215, 1.4534496453454941, 1.158116869787037,
      0.8523163825354618, 0.5398193443929321, 0.2857760266161578,
      0.12952998372507438, -0.003958120823677627, -0.12143927131265667,
      -0.3078670485749822, -0.5000962383129559, -0.6259383630323334,
      -0.6437331850063216, -0.7297212071258984, -0.8112294503988894,
      -0.8716450219135741, -0.7975278719503103, -0.7265188973306447,
      -0.764364979373405, -0.8170220080261525, -0.8724805854257931,
      -0.8246503576774591, -0.8424615169875863, -0.8296728282442513,
      -0.7136821053644921, -0.6089545479678882, -0.3342190144617903,
      -0.08370387180117268, 0.12019890702075638, -0.006931318347214744,
      -0.17307854623400265, -0.3000942354855979, 0.013273352810217168,
      0.29783124490514085, 0.8323649692584585, 1.1744139992063831,
      1.467282823373793, 1.3663978536725894, 1.019424292589823,
      0.6132511310009542, 0.29043803584799244, 0.0015798162448659825,
      -0.25004391951372335, -0.4163898546499773, -0.5078085782309798,
      -0.6667168513920659, -0.777465375755493, -0.8975604656190509,
      -1.0017606724363914, -1.207830781403257, -1.3269904194230953,
      -1.2020587040915796, -0.9756356325389566, -0.9483548720427527,
      -0.7845181697609551, -0.5460215181981879, -0.1737926080140221,
      -0.07781181170932737, 0.17713376145903012, 0.5415334212465344,
      0.8804802423980166, 1.055381585157732, 1.2561896542794408,
      1.5245850262531138, 1.7134962407577599, 1.7226559415624363,
      1.6261791133149175, 1.476194545754159, 1.322848137809521,
      0.9821407310107064, 0.6549698447117381, 0.35200474046444113,
      0.15364558564860856, -0.036769837930897456, -0.2383320720143509,
      -0.26175359760921924, -0.27765039698077815, -0.28290574135912283,
      -0.3844133095597136, -0.5530102792740098, -0.583558961517329,
      -0.6585286987400586, -0.7023650647070633, -0.9072727649061769,
      -0.9562915433453753, -0.9392810866504852, -0.8942848203700229,
      -0.629891128170403, -0.20571829120850466, 0.21987289912899854,
      0.5128074540854302, 0.49199519017734494, 0.4977847911320652,
      0.38000209944257196, 0.5949348960489798, 0.742146217603264,
      0.7087635576382338, 0.37725866224615184, 0.08902914446160803,
      -0.034930047757924264, -0.023511226904007365, -0.12927710337410184,
      -0.2799397237687767, -0.5115996823657593, -0.6089994612804112,
      -0.7380923120678088, -0.8102012788065228, -0.9528883888828873,
      -0.9748303847455358, -1.0754429384164006, -1.0583701220622919,
      -1.1407141754181274, -1.0296444354538303, -1.0260971935408263,
      -0.9619746691074836, -0.7733691559903594, -0.5517369119308398,
      -0.27804615440986, 0.15532012460024097, 0.6350531788652297,
      1.1161375535642273, 1.500511046210566, 1.9092732468784916,
      2.178441273392747, 2.1500097212710574, 1.9142108564542089,
      1.6862732231993813, 1.338280606928867, 1.0555682293113027,
      0.7276758769677409, 0.4370432386812188, 0.13318305867784097,
      -0.14794276050948832, -0.35300769303685103, -0.5402688815526017,
      -0.7134962241127782, -0.8572355090839274, -1.0647817713783139,
      -1.1541546436946626, -1.210000082882589, -1.1261725239939036,
      -1.0852626898608753, -0.9608976463279678, -0.8826798834751999,
      -0.7032740856636139, -0.23882199320578903, 0.4875382408743804,
      1.2980653130402955, 1.6337203385058523, 1.6262865991041258,
      1.2541814990715738, 1.0080454759398887, 0.7696222136823777,
      0.601292395406217, 0.30061164585794586, 0.09286378840871415,
      -0.08666303161602237, -0.31559065602966313, -0.5750448218907009,
      -0.8503584928036684, -1.0238038930160591, -1.2231818824415965,
      -1.3793265583399834, -1.529541928371217, -1.5452968056981866,
      -1.3792405915429253, -1.18074108544321, -1.1730659047125775,
      -1.0234497603219437, -0.7042763927456889, 0.06074881259717262,
      0.9292937868163726, 1.7938936416733005, 2.20158215027985,
      2.2030769440592746, 1.9099581328177462, 1.6398170279486604,
      1.314981590936223, 1.1043631066265722, 0.9310900870463855,
      0.7115245158249893, 0.4365329536705841, 0.18219277802751882,
      -0.03531693510631278, -0.28809077087254326, -0.5648432786474115,
      -0.8256358704988116, -1.1342279695405113, -1.4126757317310927,
      -1.6193556995395577, -1.5371825123242704, -1.6175922781327812,
      -1.5274715144169364, -1.3612415846804256, -0.8371256094566063,
      -0.6393504483465938, -0.7705137806500099, -1.0359208314540689,
      -0.8025140344438052, 0.09118542301804872, 1.1350680218422031,
      1.9740738466884642, 2.257189520112566, 2.3350719717518276,
      2.2092035452878553, 1.9871493202472148, 1.718586276466513,
      1.419004552570681, 1.1580123521884307, 0.8973324516502502,
      0.6366917066511093, 0.3849480611114405, 0.14246308869622662,
      -0.10539264462373521, -0.3455249082864213, -0.5933570515660004,
      -0.8208498997222469, -1.0502913969376209, -1.2235161880154908,
      -1.3619032632665906, -1.4461653856344523, -1.5345989954369559,
      -1.436915541953067, -1.4409558790752761, -1.4399304160506465,
      -1.4638369602809829, -1.1076334669808967, -0.9779769987819729,
      -0.9470436847331204, -0.8130975604799928, -0.5113616884287213,
      -0.08594528546844434, 0.09015356999681111, 0.7081400100933738,
      1.1949873511504432, 1.734011892282163, 1.983842716694704,
      2.153787052708346, 2.1094286353305067, 1.8922676422793663,
      1.7178662582890005, 1.4898005990917569, 1.291710209961426,
      1.013124272234439, 0.8035673031117019, 0.5838208352051594,
      0.3687390814192402, 0.06962841140133032, -0.1842062173815529,
      -0.4062481903736613, -0.5784468039037183, -0.8155244355721577,
      -1.026546589844316, -1.2209998857531796, -1.2528404392983001,
      -1.3314097827971503, -1.313575135747149, -1.4374000572788597,
      -1.4264784425386254, -1.5307197284701697, -1.4386735970666278,
      -1.2885736571506297, -1.0354594663430614, -0.9837066907013527,
      -0.8240266898725105, -0.385928308022948, 0.17641140994919524,
      0.6345993599404806, 0.868714636350062, 1.199541321903413,
      1.3711313854541127, 1.5003823570412023, 1.4592750028322854,
      1.4435310350361368, 1.338946458589223, 1.2409066636929555,
      1.0661817600588532, 0.7571043439492947, 0.39821079130570375,
      0.012284258930504461, -0.30365218420370815, -0.6655594149334718,
      -0.9199633065411104, -1.171993059021622, -1.2672739850706216,
    ],
    note: "Estimate of the ppg waveform using VitalLens, along with frame-wise confidences between 0 and 1.",
    unit: "unitless",
  },
  respiratory_rate: {
    confidence: 0.9977039236161444,
    note: "Estimate of the global respiratory rate using VitalLens, along with a confidence level between 0 and 1.",
    unit: "bpm",
    value: 8.700483360186677,
  },
  respiratory_waveform: {
    confidence: [
      0.9482969641685486, 0.9777731895446777, 0.9805079698562622,
      0.9870133399963379, 0.46099716424942017, 0.9132080078125,
      0.9964321851730347, 0.9993498921394348, 0.9993867874145508,
      0.9993024468421936, 0.9999368190765381, 0.9998452663421631,
      0.9996387958526611, 0.9995638132095337, 0.9996250867843628,
      0.9997767806053162, 0.9995090961456299, 0.9998374581336975,
      0.9998911619186401, 0.9999063014984131, 0.9999505281448364,
      0.9999474287033081, 0.9999839663505554, 0.9999920129776001,
      0.9999984502792358, 0.9999983310699463, 0.9999992251396179,
      0.9999992847442627, 0.9999996423721313, 0.999998927116394,
      0.9999985694885254, 0.9999996423721313, 0.9999998211860657,
      0.9999996423721313, 0.9999998807907104, 0.9999998211860657,
      0.9999999403953552, 0.9999998807907104, 0.9999997615814209,
      0.9999996423721313, 0.9999998807907104, 0.9999997615814209,
      0.9999999403953552, 0.9999998807907104, 0.9999999403953552,
      0.9999998807907104, 0.9999997615814209, 0.9999995827674866,
      0.9999997019767761, 0.9999991655349731, 0.9999980926513672,
      0.9999969005584717, 0.9999992251396179, 0.9999977946281433,
      0.9999966025352478, 0.9999943971633911, 0.9999974966049194,
      0.9999970197677612, 0.9999960660934448, 0.9999734163284302,
      0.99998539686203, 0.9999772310256958, 0.9999829530715942,
      0.9999825954437256, 0.9999983310699463, 0.999993622303009,
      0.9999979734420776, 0.999997615814209, 0.9999997615814209,
      0.9999991655349731, 0.9999984502792358, 0.9999865293502808,
      0.9999902248382568, 0.9999510049819946, 0.9997535943984985,
      0.9994375705718994, 0.9996666312217712, 0.999480128288269,
      0.9998170137405396, 0.9992278814315796, 0.9999058246612549,
      0.9999863505363464, 0.9999908804893494, 0.9999868869781494,
      0.9999976754188538, 0.9999935626983643, 0.9999839067459106,
      0.9999903440475464, 0.999999463558197, 0.9999988079071045,
      0.9999988675117493, 0.9999855756759644, 0.9999963045120239,
      0.9999969601631165, 0.9999850392341614, 0.9999524354934692,
      0.9999769926071167, 0.9999920129776001, 0.9999819397926331,
      0.9999912977218628, 0.9999990463256836, 0.9999982118606567,
      0.9999988079071045, 0.9999980926513672, 0.9999986886978149,
      0.9999986886978149, 0.9999989867210388, 0.9999957084655762,
      0.9999997019767761, 0.9999997615814209, 0.9999995827674866,
      0.9999997019767761, 0.9999997615814209, 0.9999984502792358,
      0.9999988079071045, 0.9999980926513672, 0.9999964237213135,
      0.9999977350234985, 0.9999986886978149, 0.9999953508377075,
      0.9999986886978149, 0.999997615814209, 0.9999954104423523,
      0.9999971389770508, 0.9999991655349731, 0.9999977350234985,
      0.9999974966049194, 0.9999982118606567, 0.9999998211860657,
      0.9999997019767761, 0.9999998807907104, 0.9999997615814209,
      0.9999998807907104, 0.9999998807907104, 0.9999998807907104,
      0.9999997615814209, 1.0, 1.0, 1.0, 0.9999998807907104, 0.9999999403953552,
      1.0, 1.0, 0.9999997615814209, 0.9999998807907104, 0.9999998807907104,
      0.9999998807907104, 0.9999997615814209, 0.9999997019767761,
      0.9999994039535522, 0.9999995231628418, 0.9999995231628418,
      0.9999994039535522, 0.9999995231628418, 0.9999986886978149,
      0.999991774559021, 0.9999985694885254, 0.9999971389770508,
      0.9999871850013733, 0.999984860420227, 0.9999957084655762,
      0.9999886751174927, 0.9999908804893494, 0.9999920725822449,
      0.9999978542327881, 0.9999984502792358, 0.9999976754188538,
      0.9999801516532898, 0.9999937415122986, 0.9999959468841553,
      0.9999975562095642, 0.999992311000824, 0.9999961853027344,
      0.9999914765357971, 0.9999983310699463, 0.9999985694885254,
      0.9999986886978149, 0.9999959468841553, 0.9999940991401672,
      0.9999683499336243, 0.9999924302101135, 0.999995231628418,
      0.9999946355819702, 0.9999923706054688, 0.9999982118606567,
      0.999992847442627, 0.9999959468841553, 0.9999860525131226,
      0.9999967813491821, 0.9999994039535522, 0.9999986886978149,
      0.9999821186065674, 0.9999937415122986, 0.9999836683273315,
      0.9999629259109497, 0.9996112585067749, 0.9998427629470825,
      0.9999707937240601, 0.9999675750732422, 0.9996433258056641,
      0.9998794794082642, 0.9998444318771362, 0.9994434118270874,
      0.9954435229301453, 0.999158501625061, 0.9998989105224609,
      0.9995408058166504, 0.99680495262146, 0.9987974166870117,
      0.9961978197097778, 0.9967869520187378, 0.9949160814285278,
      0.9994138479232788, 0.9996422529220581, 0.9998433589935303,
      0.9998598694801331, 0.9999814033508301, 0.9999929666519165,
      0.9999963641166687, 0.9999973773956299, 0.9999996423721313,
      0.9999995231628418, 0.9999998211860657, 0.9999998211860657,
      0.9999998807907104, 0.9999997615814209, 0.9999998211860657,
      0.9999997615814209, 0.9999998807907104, 0.9999998807907104,
      0.9999997615814209, 0.9999997615814209, 0.9999998807907104,
      0.9999998807907104, 0.9999998807907104, 0.9999997615814209, 1.0, 1.0, 1.0,
      1.0, 0.9999999403953552, 1.0, 1.0, 0.9999998807907104, 1.0, 1.0, 1.0, 1.0,
      1.0, 1.0, 1.0, 0.9999998807907104, 0.9999999403953552, 1.0, 1.0, 1.0, 1.0,
      0.9999998807907104, 1.0, 0.9999998807907104, 0.9999998807907104,
      0.9999997019767761, 1.0, 0.9999998807907104, 0.9999999403953552,
      0.9999997019767761, 1.0, 0.9999998807907104, 1.0, 1.0, 1.0,
      0.9999998807907104, 0.9999998807907104, 0.9999998807907104, 1.0, 1.0, 1.0,
      1.0, 1.0, 1.0, 0.9999999403953552, 0.9999999403953552, 1.0,
      0.9999998211860657, 0.9999998807907104, 0.9999998807907104,
      0.9999999403953552, 0.9999998807907104, 0.9999998807907104,
      0.9999995231628418, 0.9999998807907104, 0.9999995827674866,
      0.9999995231628418, 0.9999989867210388, 0.9999978542327881,
      0.9999946355819702, 0.9999974966049194, 0.9999978542327881,
      0.9999993443489075, 0.9999988079071045, 0.9999873638153076,
      0.9999740123748779, 0.9999929070472717, 0.9999780654907227,
      0.999919056892395, 0.9999818205833435, 0.9999915957450867,
      0.9999862313270569, 0.9999961853027344, 0.9999906420707703,
      0.9999623894691467, 0.999931812286377, 0.9999827146530151,
      0.9999902248382568, 0.9999918937683105, 0.9998733997344971,
      0.9996825456619263, 0.9991519451141357, 0.9968652725219727,
      0.9962546825408936, 0.9993495941162109, 0.9984732866287231,
      0.9930661916732788, 0.9891839027404785, 0.9878268241882324,
      0.9887255430221558, 0.9969580173492432, 0.9985271692276001,
      0.9994993209838867, 0.9998626708984375, 0.9999775886535645,
      0.9999680519104004, 0.9999988079071045, 0.9999960660934448,
      0.9999991059303284, 0.9999983906745911, 0.9999945163726807,
      0.9999979734420776, 0.9999998211860657, 0.9999996423721313,
      0.9999997615814209, 0.9999998211860657, 0.9999998807907104, 1.0, 1.0,
      0.9999998807907104, 1.0, 0.9999998211860657, 0.999998152256012,
      0.9999986886978149, 0.9999995827674866, 0.9999985694885254,
      0.9999991655349731, 0.9999990463256836, 0.999993085861206,
      0.9999858736991882, 0.9999977946281433, 0.9999668598175049,
      0.999992847442627, 0.9999909996986389,
    ],
    data: [
      2.362500930241618, 2.395926055366401, 2.4587122114426783,
      2.562063935477426, 2.502511774255261, 2.465735835057135,
      2.4641448647690467, 2.4570850630618035, 2.391734461172236,
      2.311287486852775, 2.381814811543313, 2.4074216642575212,
      2.3724588506063085, 2.3090403825767805, 2.2293305350514636,
      2.16601034661579, 2.0598326417399146, 1.9573719733721, 1.8095208759493886,
      1.6476510787356298, 1.439173425708521, 1.2533530833575233,
      1.0615400712596028, 0.8746643598768368, 0.6974001587309615,
      0.5366233419652062, 0.3356304558228833, 0.14731558646686474,
      -0.07962719691580437, -0.2857699495242267, -0.4893102885052341,
      -0.6612936609050473, -0.8341222711367422, -0.9460062790796373,
      -1.0539404616153352, -1.1608617672705204, -1.2852120105835887,
      -1.3793414666489712, -1.4745671906002142, -1.5293623086775625,
      -1.5737347722189279, -1.5796169669624667, -1.5790841388588317,
      -1.5581584384332763, -1.5451125765685956, -1.5132365284439393,
      -1.4857560123691058, -1.4609805678520786, -1.415556068017434,
      -1.3469620397267867, -1.3042496443541074, -1.249628963374322,
      -1.197356781593552, -1.1407522003811028, -1.1043594683651563,
      -1.0564222966484187, -1.0278057741245048, -0.9889540740803089,
      -0.9547829153224978, -0.9237325429372237, -0.890275187869392,
      -0.8570870675826362, -0.8360793642618414, -0.8071380182720882,
      -0.7730175356958943, -0.7472182776551964, -0.7241025241475207,
      -0.6753535075108503, -0.6349469697246446, -0.5739943575236384,
      -0.537674270959414, -0.4979479581261502, -0.4556404448870089,
      -0.39535866488824434, -0.36856764241576495, -0.34733982799042223,
      -0.32956204758402297, -0.32030792023185833, -0.3122303004304413,
      -0.2971102460419523, -0.2897186198232299, -0.29429098897919836,
      -0.3299147942435749, -0.3583324907262891, -0.37479821873496755,
      -0.38475691303665277, -0.425832750473494, -0.43939764626746214,
      -0.42917293620407226, -0.4220895521395366, -0.41890780920101384,
      -0.4275124944432254, -0.4440233021229929, -0.47454026007601097,
      -0.5089332942238671, -0.5301368888952058, -0.546975936948828,
      -0.560028366317734, -0.604477181961758, -0.632287010737544,
      -0.6568351486995567, -0.6919092208153331, -0.73405856009055,
      -0.7824420455033421, -0.8150517852133788, -0.8457371111470767,
      -0.8618730868981993, -0.8556403837231742, -0.8492184628128124,
      -0.8303805017421131, -0.8375427743011424, -0.8129874324740618,
      -0.8002000312680756, -0.7823157794090994, -0.7599577909313916,
      -0.7191345645605769, -0.6739346163317468, -0.6305145444496841,
      -0.5920697075254738, -0.5475710959166535, -0.5157307456137605,
      -0.48567184912287464, -0.46923959534330956, -0.44162086361432623,
      -0.4331435504495753, -0.408729875282468, -0.3998051727451572,
      -0.40794815723700395, -0.4216063618362174, -0.4234963787904039,
      -0.4507991884247351, -0.4859496731838344, -0.5328461536701852,
      -0.5662505839822178, -0.606933151856822, -0.6445526272216784,
      -0.6852131865554433, -0.7051196059191251, -0.7378828695536492,
      -0.7687513652639393, -0.7855126363626909, -0.79482560533811,
      -0.8098254453647059, -0.7956075637982658, -0.7669692425483449,
      -0.7310217792917065, -0.7076408947818602, -0.665167410259813,
      -0.6174892664391114, -0.5570773606485819, -0.515063947677356,
      -0.46877601307356465, -0.39880964322038137, -0.3436961496365145,
      -0.31154338508833895, -0.2799875992447705, -0.2553375150202245,
      -0.23174714518387654, -0.21622599555208843, -0.2021492141177302,
      -0.1935933619268481, -0.16940665342581007, -0.16425323698248243,
      -0.16973625908855736, -0.17240927712751591, -0.19436073296101508,
      -0.2158785499943944, -0.21872067778635063, -0.2193844365983197,
      -0.2138233785135383, -0.20342716050422985, -0.18740876224435046,
      -0.1707772705078171, -0.13946790594957686, -0.12660382829900402,
      -0.11608733606169883, -0.07532621557295437, -0.030934348168008448,
      0.010444267944970347, 0.07199640813919078, 0.12231688840505396,
      0.17620933740094694, 0.22117986068340564, 0.24184537555915722,
      0.27182941711455544, 0.3377399842703339, 0.3755172488288818,
      0.41461464207982823, 0.48426802508240085, 0.5628712722537803,
      0.6011381798947968, 0.6223390537266675, 0.6383049249339185,
      0.6767544536916607, 0.6857128482283387, 0.7127376633013689,
      0.7612482021401029, 0.834989456268236, 0.8691314373641218,
      0.8706997715197514, 0.920020980664924, 0.9813525857232736,
      1.0003885653973745, 1.0769111180958146, 1.1819202511144316,
      1.2895383746822977, 1.3800362820967507, 1.515217333923877,
      1.6427960919179538, 1.8007639199469003, 1.9033554979223812,
      1.98394195974542, 2.1193369508953643, 2.2086755543090084,
      2.2296143285181476, 2.278921415759571, 2.305890804548092,
      2.325299527038827, 2.3422243876565783, 2.348748570690548,
      2.3212766684307096, 2.28270329136684, 2.1811636865575292,
      2.0928222148613496, 2.043802946170239, 1.9830270269404975,
      1.9009543626531011, 1.8446770144975706, 1.7750320337413388,
      1.7108399303532025, 1.6330099197481278, 1.5195901283274578,
      1.394403757478782, 1.2910981787316416, 1.1846624413487186,
      1.09202342452467, 1.0008627788206, 0.8873900036279897, 0.7505914878505443,
      0.6482230955903703, 0.5164087223240252, 0.3914009966501678,
      0.2553720350095472, 0.1392562295026102, 0.04526930481822817,
      -0.01909390190124879, -0.11846388199071149, -0.21053779770623016,
      -0.28860739856516004, -0.36653188760671757, -0.4354603886811237,
      -0.4628122550711411, -0.5053275701416887, -0.5139791048362329,
      -0.5091291500574624, -0.5078052516128414, -0.5204020114718081,
      -0.5266743837306006, -0.5403183522671895, -0.5443158861494481,
      -0.5404615205750437, -0.5454459562128545, -0.5571713931449424,
      -0.5663887054774168, -0.596104981406741, -0.6358403163252468,
      -0.6581995218768283, -0.7034440813254142, -0.7531615911651903,
      -0.7973387401256866, -0.8459132403129941, -0.8934740980336732,
      -0.9207532509175474, -0.9601592543308727, -0.9987655213518706,
      -1.005904764895768, -1.0147787337275538, -1.0287378978417365,
      -1.017060018041378, -1.0236575950737088, -1.0243560226057122,
      -1.0014828988627802, -0.9765964434620337, -0.9391124981293771,
      -0.8893534484603953, -0.850405996940622, -0.79801284202557,
      -0.7264867608569175, -0.6758857990091207, -0.6404221779078806,
      -0.5934590051617711, -0.5673188177285935, -0.5319703441330099,
      -0.4889915301032663, -0.45621870024451777, -0.43224439890136584,
      -0.4172553071295557, -0.4066890414481234, -0.40043285636846454,
      -0.40046023269139597, -0.4055266653380246, -0.42396076152989726,
      -0.4153140289783823, -0.4080960941535276, -0.37703923081720964,
      -0.3561282029685446, -0.3610808847244289, -0.37070221647912877,
      -0.3662278062214317, -0.3455520697615448, -0.31587616489423254,
      -0.2588938585283567, -0.18662114818437617, -0.13402541071774896,
      -0.08030135078572534, -0.008487692163495776, 0.09207874310122892,
      0.18136599234612444, 0.27978987074072303, 0.3956678248229919,
      0.5246429298940574, 0.6282605051145471, 0.7091299291049996,
      0.7723575155284743, 0.784949179975468, 0.7921309015121908,
      0.7764155886615864, 0.7799704428687433, 0.7412556867551336,
      0.6736026004036381, 0.6022778509410898, 0.4938166501321218,
      0.37899691791336654, 0.24435953417833733, 0.13401599103529835,
      0.042852830877750665, -0.05038259312335103, -0.14535406667076364,
      -0.24939780723451455, -0.29953213654430333, -0.3543425565747526,
      -0.3908555154304198, -0.417940239858508, -0.45191039881859957,
      -0.45972868746259793, -0.44732639079424696, -0.4182519462631587,
      -0.38098412025995293, -0.34464274935870765, -0.303791696793153,
      -0.23399078063862025, -0.13099771568872082, -0.052657920932844084,
      0.03224847071216361, 0.11258404614304053, 0.17186114475690195,
      0.24055418796988434, 0.2815397519711447, 0.31522676098166597,
      0.3402594409271387,
    ],
    note: "Estimate of the respiratory waveform using VitalLens, along with frame-wise confidences between 0 and 1.",
    unit: "unitless",
  },
  running_heart_rate: {
    confidence: [
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8704266334573428, 0.8704266334573428, 0.8704266334573428,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.8870547817150751, 0.8870547817150751, 0.8870547817150751,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
      0.9036829299728075, 0.9036829299728075, 0.9036829299728075,
    ],
    data: [
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.91015169194866, 60.91015169194866, 60.91015169194866,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.61010168361394, 60.61010168361394, 60.61010168361394,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
      60.31005167527922, 60.31005167527922, 60.31005167527922,
    ],
    note: "Estimate of the running heart rate using VitalLens, along with frame-wise confidences between 0 and 1.",
    unit: "bpm",
  },
};
const labels = Array.from({ length: 30 }, (_, i) => i + 1);
const ChartComponent = ({ label, data, color }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        borderColor: color,
        backgroundColor: color.replace("rgb", "rgba").replace(")", ", 0.5)"),
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true, // Enable legend
        position: "bottom",
      },
      title: {
        display: false,
        text: label,
      },
    },
  };

  return (
    <div>
      <Line options={options} data={chartData} />
    </div>
  );
};

const VitalsDashboard = ({ data }) => {
  console.log(data);
  const report = data;
  return (
    <div className="">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h6" align="center">
              Heart Rate (bpm)
            </Typography>
            <Typography variant="subtitle1" align="center">
              {report.heart_rate.value}
            </Typography>
            <ChartComponent
              label="Heart Rate (bpm)"
              data={report.ppg_waveform.data}
              color="rgb(255, 99, 132)"
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h6" align="center">
              Respiratory Rate
            </Typography>
            <Typography variant="subtitle1" align="center">
              {report.respiratory_rate.value}
            </Typography>
            <ChartComponent
              label="Respiratory Rate"
              data={report.respiratory_waveform.data}
              color="rgb(75, 192, 192)"
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h6" align="center">
              SpO2 (%)
            </Typography>
            <Typography variant="subtitle1" align="center">
              {0}
            </Typography>
            <ChartComponent
              label="SpO2 (%)"
              data={[]}
              color="rgb(53, 162, 235)"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h6" align="center">
              Depression Score
            </Typography>
            <Typography variant="subtitle1" align="center">
              {0}
            </Typography>
            <ChartComponent
              label="Depression Score"
              data={[]}
              color="rgb(255, 159, 64)"
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default VitalsDashboard;
