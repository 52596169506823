import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useCreateConsultation } from "../../hooks/useConsultation";
import { useNavigate } from "react-router-dom";
import Loader from "../Utility/Loader";
export default function FormDialog({ open, setOpen, item }) {
  const [name, setName] = React.useState();
  const { data, loading, createConsultation } = useCreateConsultation();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.id) {
      setOpen(false);
      navigate(`/consultation/${data.id}`);
    }
  }, [data]);
  const handleClickOpen = () => {
    setOpen(true);
    createConsultation({ name, type:item.type });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        {loading && <Loader />}
        <DialogTitle>{item.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {item.formDescription}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="name"
            fullWidth
            value={name}
            variant="standard"
            onChange={(event) => setName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClickOpen}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
