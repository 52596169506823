import * as React from "react";
import Box from "@mui/material/Box";
import { useMyConsultations } from "../../hooks/useConsultation";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Loader from "../Utility/Loader";
import Moment from "moment";
import { Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import GroupIcon from '@mui/icons-material/Group';
import EditNoteIcon from '@mui/icons-material/EditNote';
const CardItem = ({ item, onclickHandler }) => {
  let { consultationId = 0 } = useParams();

  const { type, id, name, createdAt } = item;
  const isActive = type === 2;
  const isSelected = consultationId == id;
  const minDifference = Moment(createdAt).fromNow();

  return (
    <Box sx={{ my: 2, mx: isSelected ? 1 : 2, cursor: "pointer" }}>
      <Paper
        elevation={isSelected ? 4 : 1}
        sx={{
          padding: 2,
          display: "flex",
          borderLeft: `solid 4px ${isActive ? "#07bd7a" : "#1ab6f5"}`,
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={() => onclickHandler(item)}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexGrow={1}
        >
          <Stack>
            <Typography variant="title" color="text.primary" fontWeight={400}>
              {name}
            </Typography>
          </Stack>
          <Typography variant="caption" color="text.secondary" fontWeight={200}>
            {minDifference}
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};
export default function ConsultationList() {
  const { data, loading, getMyConsultations } = useMyConsultations();
  const navigate = useNavigate();
  React.useEffect(() => {
    getMyConsultations();
  }, []);
  const onclickHandler = (item) => {
    navigate(`/consultation/${item.id}`);
  };
  if (loading) {
    return <Loader />;
  }
  if (data) {
    return (
      <Box sx={{ overflow: "scroll" }}>
        {!data && <Loader />}
        <List>
          {data.map((item) => (
            <CardItem
              key={item.id}
              item={item}
              onclickHandler={onclickHandler}
            />
          ))}
        </List>
      </Box>
    );
  }
}
