import React, { useEffect, useState } from "react";
import Moment from "moment";
import { StreamChat } from "stream-chat";
import {
  Channel,
  Chat,
  MessageInput,
  MessageList,
  Thread,
  Window,
  MessageSimple,
  useMessageContext,
} from "stream-chat-react";
import "./chat.css";
import Loader from "../Utility/Loader";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import VideoCallIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import { useGetChatAuth } from "../../hooks/useConsultation";
const CustomMessage = ({ caseId }) => {
  const { message } = useMessageContext();
  const navigate = useNavigate();
  const handleJoinCall = () => {
    navigate(`/care/${caseId}/appointment/${caseId}`);
  };
  const messageUser = message.user.id;
  const messageAction = message.message_action;
  const sentAt = message.updated_at;
  const currentDate = new Date().getTime();
  const minDifference = Moment(sentAt).diff(Moment(currentDate), "m");

  if (messageUser === "system" && messageAction === "case_create_appointment") {
    const { appointment } = message.extraData || {};
    if (appointment) {
      return (
        <Box display="flex" marginLeft="46px">
          {/* <iframe
            scrolling="no"
            frameBorder="0"
            style={{
              height: "368px",
              width: "280px",
              border: "1px solid rgba(0, 0, 0, 0.08)",
              borderRadius: "16px 16px 16px 2px",
            }}
            src={appointment}
          /> */}
        </Box>
      );
    }
  } else if (messageUser === "system" && messageAction === "join_call") {
    const isOldMessage = minDifference < -10;
    return (
      <Box display="flex" justifyContent="center" mb={2}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="1px solid rgba(0, 0, 0, 0.08)"
          borderRadius="8px"
          px={1}
        >
          <Typography variant="caption">{message.text}</Typography>
        </Box>
        <Box pr={1} />
        {!isOldMessage && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{ textTransform: "none" }}
            onClick={handleJoinCall}
            startIcon={<VideoCallIcon />}
          >
            Join
          </Button>
        )}
      </Box>
    );
  } else if (messageUser === "system") {
    return (
      <Box display="flex" justifyContent="center" mb={2}>
        <Box border="1px solid rgba(0, 0, 0, 0.08)" borderRadius="8px" px={1}>
          <Typography variant="caption">{message.text}</Typography>
        </Box>
      </Box>
    );
  }
  return <MessageSimple {...message} />;
};

const ChatView = ({ consultation }) => {
  const { getChatAuth, data: chatAuth, loading } = useGetChatAuth();
  const [channel, setChannel] = useState();
  const [chatClient, setChatClient] = useState();
  const [enableInput, setEnableInput] = useState(false);

  const caseId = consultation.id;
  const status = consultation.status;
  const memberId = consultation.memberId;
  const channelId = `case_${caseId}`;


  useEffect(() => {
    setEnableInput(status !== "closed");
  }, [status]);

  useEffect(() => {
    getChatAuth();
  }, []);

  useEffect(() => {
    const initChat = async () => {
      let channelInt;
      if (caseId && chatAuth) {
        const chatClientInt = new StreamChat(chatAuth.apiKey);
        await chatClientInt.connectUser(
          { id: chatAuth.userId },
          chatAuth.authToken
        );
        setChatClient(chatClientInt);
        channelInt = chatClientInt.channel("case", `${channelId}`, {});
        await channelInt.watch();
        setChannel(channelInt);
        subscribeEvents(channelInt);
      }
      return () => unSubscribeEvents(channelInt);
    };

    initChat();
  }, [caseId, chatAuth]);

  const listenEvents = (event) => {
    console.log(event);
    const messageUser = event.user.id;
    if (messageUser === "system") {
      const messageAction = event.message.message_action;
      switch (messageAction) {
        case "system_typing":
          console.log("system_typing");
          break;
        case "join_call":
          console.log("Call Received");
          break;
        case "left_call":
          console.log("Call Hang");
          break;
        case "case_close":
          setEnableInput(false);
          // getCaseDetailsRequest(caseId);
          break;
        case "case_reopen":
          setEnableInput(true);
          // getCaseDetailsRequest(caseId);
          break;
        case "case_assign_provider":
          // getCaseDetailsRequest(caseId);
          break;
        case "case_unassign_provider":
          // getCaseDetailsRequest(caseId);
          break;
        default:
          break;
      }
    }
  };
  const subscribeEvents = (channelInt) => {
    channelInt && channelInt.on("message.new", listenEvents);
  };
  const unSubscribeEvents = (channelInt) => {
    channelInt && channelInt.off("message.new", listenEvents);
  };

  if (!chatClient && !channel) {
    return <Loader />;
  } else {
    return (
      <Chat client={chatClient} theme="messaging light">
        <Channel
          channel={channel}
          Message={() => <CustomMessage caseId={caseId} />}
        >
          <Window>
            <MessageList />
            {enableInput && (
              <MessageInput
                doFileUploadRequest={(file, channel) => {
                  return chatClient.sendFile(`https://api.unifi.dev/files?consultationId=${caseId}&memberId=${memberId}`, file) ; // or your custom upload request.
                  // return chatClient.sendFile(`http://localhost:3000/files?consultationId=${caseId}&memberId=${memberId}`, file) ; // or your custom upload request.
                }}
              />
            )}
          </Window>
          <Thread />
        </Channel>
      </Chat>
    );
  }
};

export default ChatView;
